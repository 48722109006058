<template>
  <app-page-primary
    :is-loading="isLoading"
    :limited-height="false"
    :body-overflow="true"
  >
    <template #header>
      <div
        class="text-h6 text-break"
      >
        Настройки
      </div>
      <v-spacer />
    </template>
    <template>
      <div>
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <!-- Профиль -->
          <v-expansion-panel>
            <v-expansion-panel-header>Профиль</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                class="col col-lg-4"
              >
                <div
                  class="app-avatar d-flex mb-5"
                >
                  <input
                    ref="input"
                    class="d-none"
                    type="file"
                    name="image"
                    accept="image/*"
                    @input="setImage"
                  >
                  <v-avatar
                    :color="(cropImg !== '' || form.avatar) ? 'secondary' : 'primary'"
                    size="100"
                    class="c-pointer"
                    @click="isLoading ? '' : showFileChooser()"
                  >
                    <img
                      v-if="cropImg !== ''"
                      :src="cropImg"
                      alt="image"
                    >
                    <img
                      v-else-if="form.avatar"
                      :src="form.avatar"
                      alt="image"
                    >
                    <div
                      v-else
                      class="secondary--text text-caption"
                    >
                      Загрузить <br>аватар
                    </div>
                  </v-avatar>

                  <v-btn
                    v-if="cropImg !== '' || form.avatar"
                    class=" "
                    fab
                    x-small
                    color="primary"
                    text
                    @click="clearImage"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>


                  <v-dialog
                    v-model="showCropperDialog"
                    width="500"
                  >
                    <v-card>
                      <v-card-text
                        class="pa-2"
                      >
                        <vue-cropper
                          ref="cropper"
                          style="max-height: 300px;"
                          :aspect-ratio="1 / 1"
                          :src="imgSrc"
                          preview=".preview"
                        />
                      </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-btn
                          color="primary"
                          text
                          @click="showCropperDialog = false"
                        >
                          Закрыть
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="cropImage"
                        >
                          Сохранить
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                <v-text-field
                  v-model="form.name"
                  label="Имя и фамилия"
                  placeholder=""
                />
                <div
                  class="d-flex justify-center"
                >
                  <v-btn
                    color="primary"
                    class="mt-2"
                    outlined
                    :disabled="isLoading"
                    @click="cancelProfile"
                  >
                    Отменить
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    class="mt-2"
                    :disabled="isLoading"
                    @click="saveProfile"
                  >
                    Сохранить
                  </v-btn>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Подключения -->

          <v-expansion-panel>
            <v-expansion-panel-header>Подключения</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                class="col col-lg-4"
              >
                <div>
                  Внимание! <br>
                  <br>
                  Strava больше нельзя подключить в данном приложении.<br>
                  <br>
                  Используйте новую версию приложения, доступную по адресу:<br>
                  <a href="https://moveplanner.ru" target="_blank">https://moveplanner.ru</a><br>
                  <br>
                  Все ваши данные доступны там же. Имя пользователя и пароль те же, что и здесь.<br>
                  <br>
                  Если вы устанавливали приложение BlackLynx App на телефон, замените его на MovePlanner.<br>
                  <br>
                  Если вы забыли пароль, вы можете восстановить его здесь:<br>
                  <a href="https://api.moveplanner.ru" target="_blank">https://api.moveplanner.ru</a><br>
                  <br>
                  Прошу прощения за неудобства!<br>
                </div>
                <div
                  v-if="false"
                  class="d-flex align-center justify-space-between"
                >
                  <div
                    class="d-flex align-center justify-space-between"
                  >
                    <v-img
                      :max-height="32"
                      :max-width="32"
                      contain
                      position="center center"
                      src="@/assets/logo-strava.png"
                      class="mr-3"
                    />
                    <div
                      class="text-uppercase font-weight-bold lh-1"
                    >
                      STRAVA
                    </div>
                  </div>
                  <div>
                    <v-btn
                      color="primary"
                      :href="stravaLink"
                      disabled
                    >
                      Подключить
                    </v-btn>
                  </div>

                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </app-page-primary>
</template>

<script>
  import axios from 'axios'
  import VueCropper from 'vue-cropperjs'
  import 'cropperjs/dist/cropper.css'
  import AppPagePrimary from '../components/AppPage/AppPagePrimary'
  import { mapActions } from 'vuex'

  export default {
    components: {
      VueCropper,
      AppPagePrimary
    },
    data() {
      return {
        showCropperDialog: false,
        imgSrc: '',
        cropImg: '',
        menu: false,
        panel: [0,1],
        isLoading: true,
        form: {
          name: '',
          avatar: null,
          avatarAction: 0,
          city: null,
          gender: null,
          birthday: null
        },
      }
    },
    computed: {
      stravaLink () {
        return 'https://www.strava.com/oauth/authorize?client_id=' + process.env.VUE_APP_CT_STRAVA_CLIENT_ID + '&response_type=code&redirect_uri=' + process.env.VUE_APP_CT_STRAVA_REDIRECT_URI + '&scope=read_all,profile:read_all,activity:read_all&state=strava';
      }
    },
    mounted () {
      this.getUser();
      if (this.$route.query.code) {
        axios({
          url: '/api/strava/auth-success?code=' + this.$route.query.code,
          method: 'GET'
        })
          .then(() => {
            this.snackbarOpen({
              color: "success",
              contentColor: "white",
              content: "Strava подключена"
            });
            this.$router.push({ name: 'user.profile' });
          })
          .catch(() => {

          });
      }
    },
    methods: {
      ...mapActions({
        dispatchMe: 'auth/me'
      }),
      ...mapActions({
        snackbarOpen: 'snackbar/snackbarOpen'
      }),
      cancelProfile () {
        this.imgSrc = '';
        this.cropImg = '';
        this.isLoading = true;
        this.getUser();
      },
      saveProfile () {
        this.isLoading = true;
        let that = this;
        let formData = new FormData();
        formData.append('name', this.form.name);
        if (this.form.birthday) {
          formData.append('birthday', this.form.birthday);
        }
        if (this.form.city) {
          formData.append('city', this.form.city);
        }
        if (this.form.gender) {
          formData.append('gender', this.form.gender);
        }
        formData.append('avatar_action', this.form.avatarAction);
        if (this.$refs.cropper && (this.form.avatarAction === 1)) {
          this.$refs.cropper.getCroppedCanvas({maxWidth: 256, maxHeight: 256}).toBlob(function (blob) {
            formData.append('file', blob);
            that.postProfile(formData, true);
          });
        } else {
          that.postProfile(formData);
        }
      },
      postProfile (data, multipart = false) {
        let options = multipart ? { headers: { 'Content-Type': 'multipart/form-data' } } : {}
        axios.post(
          '/api/user/update',
          data,
          options
        )
          .then(
            () => {
              this.dispatchMe();
              this.snackbarOpen({
                color: "success",
                contentColor: "white",
                content: "Данные обновлены"
              });
            })
          .catch(
            () => {
              this.snackbarOpen({
                color: "error",
                contentColor: "white",
                content: "Ошибка при сохранении"
              });
            })
          .finally(
            () => {
              this.isLoading = false;
            });
      },
      getUser () {
        axios.get('/api/user')
          .then((response) => {
            this.form = response.data
            this.form.avatarAction = 0;
          })
          .catch(() => {

          })
          .finally(() => {
            this.isLoading = false;
          })
      },
      showFileChooser () {
        this.$refs.input.value = '';
        this.$refs.input.click();
      },
      setImage (e) {
        const file = e.target.files[0];
        if (file.type.indexOf('image/') === -1) {
          // snackbar
          return;
        }
        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(event.target.result);
          };
          reader.readAsDataURL(file);
          this.showCropperDialog = true;
        } else {
          // snackbar
        }
      },
      cropImage () {
        this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.avatarAction = 1;
        this.showCropperDialog = false;
        //this.saveImage();
      },
      clearImage () {
        this.cropImg = '';
        this.$refs.input.value = '';
        if (this.form.avatar) {
          this.form.avatar = null;
          this.form.avatarAction = -1;
        } else {
          if (this.form.avatarAction === 1) {
            this.form.avatarAction = 0;
          }
        }
      },
    }
  }
</script>

<style lang="scss">
  .my-races-list {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
  .pulse-table {
    .row {
      &:first-child {
        .col {
          border-top: 1px solid var(--v-primary-base);
        }
      }
      .col {
        border-right: 1px solid var(--v-primary-base);
        border-bottom: 1px solid var(--v-primary-base);
        &:first-child {
          border-left: 1px solid var(--v-primary-base);
        }
      }
    }
  }
</style>
